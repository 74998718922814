import Vue from 'vue'
import VueScrollTo from 'vue-scrollto';
import App from './App.vue'
// 配置elementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.css';
import '@/assets/css/home.css';

//配置axios
import axios from 'axios';
import router from './router'

//设定axios的请求根目录
axios.defaults.baseURL = '/api'
//跨域携带cookie开启
axios.defaults.withCredentials = true;


// 配置elementUi
Vue.use(ElementUI);
Vue.use(VueScrollTo);


//配置axios
Vue.prototype.axios = axios

Vue.config.productionTip = false

//主页面
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

//切换页面自动滑动到顶部
router.afterEach((to,from,next) => {

  window.scrollTo(0,0);
});

