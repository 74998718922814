<template>

  <div style="margin-bottom: -50px;">
    <!--      全屏轮播开始-->
    <div class="el_bar">
      <!--          轮播开始-->
        <el-carousel :interval="3000" height="95vh" arrow="never" @change="handleChange" :style=yd_size>
          <el-carousel-item v-for="c in CarouselArr">
            <div>

              <div
                  style="background-color: rgba(37,137,241,0.43);width: 100%;height: 15vh;position: absolute;margin-top: 15%;">
                <p class="banner-content" v-show="showText">{{ bannerContent }}</p>
              </div>

              <img style="width:100%;height: 95vh;" :src="c.url" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      <!--          轮播结束-->
      <div id="CP"></div>
    </div>
    <!--      全屏轮播结束-->

    <div class="ho_ma" style="margin-top: 120px;margin-bottom: 60px;">
      <!--    服务开始-->
      <div style="height: 600px">
        <div class="page_title" style="margin-bottom: -30px">
          <div class="fw_text">
            <div style="width: 75px;height: 1px;background-color: #000c33;"></div>
            <p style="font-size: 32px;margin: 0 1%;">
              公司能力
            </p>
            <div style="width: 75px;height: 1px;background-color: #000c33;"></div>
          </div>
          <p class="el_ts">
            Company Capability
          </p>
        </div>
        <!--    服务结束-->

        <!--服务板块开始-->
        <div class="fw_fa">

          <div id="top-left" class="corner-div" @mouseenter="onEnterTd(1)" @mouseleave="onLeaveTd(1)">



            <transition :name=el_dh>
              <div class="top_left_bg fw_bg" v-show="show1">
                <div style="margin: 20px ;">
                  <p class="fw_tit">
                    设计咨询
                  </p>
                  <div style="margin-bottom: 8px"></div>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    为企业客户提供设计咨询能力，包括用户可行性
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    分析、需求挖掘、ROI评估、POC设计、落地方案制定。
                  </p>
                </div>
              </div>
            </transition>

            <transition :name=el_dh>
              <div v-show="show1_1">
                <div class="fw_size fw_mar1"></div>
                <span style="font-size: 26px;margin-left: 42%">
              设计咨询
              </span>
              </div>
            </transition>

          </div>


          <div id="top-right" class="corner-div" @mouseenter="onEnterTd(2)" @mouseleave="onLeaveTd(2)">



            <transition :name=el_dh>
              <div class="top-right_bg fw_bg" v-show="show2">
                <div style="margin: 20px;">
                  <p class="fw_tit">
                    数字化提升
                  </p>
                  <div style="margin-bottom: 8px"></div>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    利用自有产品、数字技术及方法
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    为企业赋能业务、流程、产品和服务，
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    从而打通企业数字孤岛，实现数字化提升。
                  </p>

                </div>
              </div>
            </transition>


            <transition :name=el_dh>
              <div v-show="show2_1">
                <div class="fw_size fw_mar2"></div>
                <span style="font-size: 25px;margin-left: 33%">
                  数字化提升
                </span>
              </div>
            </transition>
          </div>

          <div id="bottom-left" class="corner-div" @mouseenter="onEnterTd(3)" @mouseleave="onLeaveTd(3)">

            <transition :name=el_dh>
              <div class="bottom-left_bg fw_bg" v-show="show3">
                <div style="margin: 20px ;">
                  <p class="fw_tit">
                    项目实施
                  </p>
                  <div style="margin-bottom: 8px"></div>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    通过自有产品，提供端到端的项目实施
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    落地方案，包括项目资源管理、范围、时间、
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    成本和风险、质量控制等，以确保项目成功实施。
                  </p>

                </div>
              </div>
            </transition>

            <transition :name=el_dh>
              <div v-show="show3_1">

                <div class="fw_size fw_mar3"></div>
                <span style="font-size: 25px;margin-left: 36%">
            项目实施
            </span>
              </div>
            </transition>

          </div>

          <div id="bottom-right" class="corner-div" @mouseenter="onEnterTd(4)" @mouseleave="onLeaveTd(4)">


            <transition :name=el_dh>
              <div class="bottom-right_bg fw_bg" v-show="show4">
                <div style="margin: 20px ;">
                  <p class="fw_tit">
                    服务运营
                  </p>
                  <div style="margin-bottom: 8px"></div>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    在项目成功交付后，建立客户成功
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    服务机制，为客户和项目的持续运营
                  </p>
                  <p style="color: rgba(255,255,255,0.89);font-size: 14px" class="fw_tit">
                    提供有力支撑，以支持企业有效管理和业务运营。
                  </p>

                </div>
              </div>
            </transition>

            <transition :name=el_dh>
              <div v-show="show4_1">
                <div class="fw_size fw_mar4"></div>
                <span style="font-size: 25px;margin-left: 36%">
            服务运营
            </span>
              </div>
            </transition>

          </div>

        </div>
        <!--服务板块结束-->
      </div>
    </div>

    <!--关于我们开始-->
    <div class="ab_nu" :style="yd_size">
      <div class="page_title" style="margin-bottom: 60px;">
        <div class="fw_text">
          <div style="width: 75px;height: 1px;background-color: #000c33;"></div>
          <p style="font-size: 32px;margin: 0 1%;">
            关于我们
          </p>
          <div style="width: 75px;height: 1px;background-color: #000c33;"></div>
        </div>
        <p class="el_ts">
          About Us
        </p>
      </div>
      <!--      关于我们轮播图-->
      <el-carousel :interval="10000" type="card" height="200px" arrow="never" style="width: 1000px;margin: 0 auto">
        <el-carousel-item v-for="c in AboutArr">
          <router-link to="/about">
            <img style="width: 100%;height: 200px;" :src="c.url" alt="">
          </router-link>
        </el-carousel-item>
      </el-carousel>

      <div class="ab_sm_ho">
        <p>
          寰宇智享创始团队起缘于2019年，由留美双硕士(电子工程与计算机)彭聪带头发起，其初心是在寰宇世界里使客户享有智能化的数字成果，进而带领已有4年制造业数字化服务经验的团队在2022年于苏州创立“寰宇智享”，且同年获得苏州吴江开发区领军人才。团队核心成员来自金融、能源、半导体等领域，均有10年以上流程、软件自动化从业经验。
          公司聚焦制造业的智能化、数字化业务提升，以可落地的 AI 模型+行业知识为基石，深耕多场景业务自动化领域，持续打磨 AI 行业模型+大数据+数字孪生等产品。以产品为核心，打造智能/数字/自动化的解决方案，逐步迈进制造业数据模型、稀土磁材工艺大数据模型、半导体业务自动化等细分行业。
          公司主旨为客户至上，提供业务端到端、场景可实现、服务可持续的行业产品+整体解决方案，包含不限于前期设计规划、咨询顾问、实施交付、后期服务运维等，致力于成为用心服务客户的智能自动化解决方案服务商。
        </p>
      </div>

    </div>
    <!--关于我们结束-->

    <!--    合作伙伴开始-->
    <div class="ab_hb" :style="yd_size">
      <div class="page_title">
        <div class="fw_text">
          <div style="width: 75px;height: 1px;background-color: #eeeeee;"></div>
          <p style="font-size: 32px;margin: 0 1%;color: white">
            合作伙伴
          </p>
          <div style="width: 75px;height: 1px;background-color: #eeeeee;"></div>
        </div>
        <p class="el_ts" style="color: #eeeeee">
          Business Partners
        </p>
      </div>


        <div class="hb_logos"></div>

    </div>
    <!--    合作伙伴结束-->

  </div>
</template>


<script>
import '@/assets/css/home.css'
import bg_1 from '@/assets/imgs/bg_1.png'
import img3 from '@/assets/imgs/1 (14)--.jpg'
import img4 from '@/assets/imgs/1 (17)--.jpg'
import img5 from '@/assets/imgs/1 (20)--.jpg'
import img6 from '@/assets/imgs/about_ba1.jpg'
import img7 from '@/assets/imgs/about_ba2.jpg'
import img8 from '@/assets/imgs/about_ba3.jpg'

export default {
  name: 'HomeView',
  data() {
    return {
      yd_size:{},
      show1: false,
      el_dh: 'el-zoom-in-center',
      show1_1: true,
      show2: false,
      show2_1: true,
      show3: false,
      show3_1: true,
      show4: false,
      show4_1: true,
      showText: true,//是否显示轮播文字
      bannerContent: '提高效率，降低成本。',//文字内容

      backgroundDiv: {
        backgroundImage: 'url(' + require('../assets/imgs/ts_bg1.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: '500px'
      },
      bg_im1: {
        backgroundImage: 'url(' + require('../assets/imgs/img15.png') + ') 100% 100% no-repeat',
        height: '500px',
      },

      custom: {
        backgroundImage: 'url(' + require('../assets/imgs/img15.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: '500px'
      },
      custom_logo: {
        cl1: {
          url: require('../assets/imgs/logo.png')
        },
        cl2: {
          url: require('../assets/imgs/logo.png')
        },
        cl4: {
          url: require('../assets/imgs/logo.png')
        },
        cl5: {
          url: require('../assets/imgs/logo.png')
        },
        cl6: {
          url: require('../assets/imgs/logo.png')
        },
        cl7: {
          url: require('../assets/imgs/logo.png')
        },

      },
      bg_fa: {
        fa1: {
          margin: '10px',
          backgroundImage: 'url(' + require('../assets/imgs/fa_gy.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '500px',
          sea: "工业制造"
        },
        fa2: {
          margin: '10px',
          backgroundImage: 'url(' + require('../assets/imgs/fa_yl.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '500px',
          sea: "医疗行业"
        },
        fa3: {
          margin: '10px',
          backgroundImage: 'url(' + require('../assets/imgs/fa_ky.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '500px',
          sea: "科学研究"
        },
        fa4: {
          margin: '10px',
          backgroundImage: 'url(' + require('../assets/imgs/fa_zn.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height: '500px',
          sea: "人工智能"
        },
      },

      svg_dn: "M993.534 77.766H30.466C13.64 77.766 0 91.406 0 108.232v652.166c0 16.826 13.64 30.466 30.466 30.466h335.126v94.438h-111.64c-16.826 0-30.466 13.64-30.466 30.466s13.64 30.466 30.466 30.466h516.112c16.826 0 30.466-13.64 30.466-30.466s-13.64-30.466-30.466-30.466h-111.64v-94.438h335.11c16.826 0 30.466-13.64 30.466-30.466V108.232c0-16.826-13.64-30.466-30.466-30.466zM597.488 885.302h-170.966v-94.438h170.966v94.438z m365.58-155.37H60.932v-51.89h902.134v51.89z m0-112.824H60.932V138.698h902.134v478.41z",
      bg_1: bg_1,
      //轮播图
      CarouselArr: [
        {url: img3},
        {url: img4},
        {url: img5},
      ],
      AboutArr: [
        {url: img6},
        {url: img7},
        {url: img8},
      ]
    };
  },
  mounted() {
    //dom元素加载完成事件

  },
  created() {
    if (this.isMobile()) {
      this.yd_size = {width: '1500px',};
    }

  },
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // localStorage.setItem('isiphone',flag)

      return flag;
    },

    //能力区域鼠标进入的事件。
    onEnterTd(e) {
      if (e == 1) {
        this.show1 = true
        this.show1_1 = false
      } else if (e == 2) {
        this.show2 = true
        this.show2_1 = false
      } else if (e == 3) {
        this.show3 = true
        this.show3_1 = false
      } else if (e == 4) {
        this.show4 = true
        this.show4_1 = false
      }
    },
    //能力区域鼠标离开的事件。
    onLeaveTd(e) {
      if (e == 1) {
        this.show1 = false
        this.show1_1 = true
      } else if (e == 2) {
        this.show2 = false
        this.show2_1 = true
      } else if (e == 3) {
        this.show3 = false
        this.show3_1 = true
      } else if (e == 4) {
        this.show4 = false
        this.show4_1 = true
      }
    },

    handleChange(currentIndex, previousIndex) {
      // 当轮播项发生改变时触发的回调函数
      // currentIndex 当前项的索引
      // previousIndex 上一个项的索引
      // console.log('当前项索引:', currentIndex);
      // 执行文字显示函数

      if (currentIndex == 0) {
        setTimeout(() => { // 设置延迟，使文字有动画效果
          this.showText = true
        }, 500); // 这里设置500毫秒的延迟，您可以根据需要调整
        this.bannerContent = '提高效率，降低成本。';

      } else if (currentIndex == 1) {
        setTimeout(() => { // 设置延迟，使文字有动画效果
          this.showText = true
        }, 500); // 这里设置500毫秒的延迟，您可以根据需要调整
        this.bannerContent = '减少错误率，提高数据准确性。';
      } else if (currentIndex == 2) {
        setTimeout(() => { // 设置延迟，使文字有动画效果
          this.showText = true
        }, 500); // 这里设置500毫秒的延迟，您可以根据需要调整
        this.bannerContent = '解放员工，探索企业创新发展。';
      }
      this.showText = false


    },
  },

};
</script>
