<template>
  <div id="app">
    <el-container>
      <!--首页头部开始-->
      <el-header class="headers" height="90px">
        <!--logo部分开始-->
        <div style="width: 100%;">
          <el-row :gutter="20" style="width: 1300px;margin: 0 auto;">
            <el-col :span="12">
              <div style="margin-top: 20px">
                <router-link to="/">
                  <img src="@/assets/imgs/logo.png"
                       class="ws_logo" alt="">
                </router-link>
              </div>
            </el-col>
            <!--            导航开始-->
            <el-col :span="12">
              <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                       @select="handleSelect" text-color="black" active-text-color="#2a2a8e">
                <!--                  <el-menu-item index="1">产品</el-menu-item>-->
                <el-menu-item index="1" style="padding-top: 10px;height: 90px;">
                  <router-link to="/">
                    <div style="height: 100%;width: 50px;">
                      首页
                    </div>
                  </router-link>
                </el-menu-item>

                <el-menu-item index="2" style="padding-top: 10px;height: 90px;">
                  <router-link to="/product" v-scroll-to="'#CP'">
                    <div style="height: 100%;width: 50px;">
                      公司产品
                    </div>
                  </router-link>
                </el-menu-item>

                <el-submenu index="3" style="padding-top: 10px;margin-left:30px">
                  <template slot="title">
                    解决方案
                  </template>
                  <router-link to="/pcb">
                    <el-menu-item index="3-1">PCB实践</el-menu-item>
                  </router-link>
                  <router-link to="/rare-earth">
                    <el-menu-item index="3-2">稀土实践</el-menu-item>
                  </router-link>
                </el-submenu>

                <el-submenu index="4" style="padding-top: 10px">
                  <template slot="title">
                    寰宇&客户
                  </template>
                  <router-link to="/about">
                    <el-menu-item index="4-1">关于寰宇</el-menu-item>
                  </router-link>
                  <!--                  <router-link to="/about" v-scroll-to="'#About_head'">-->
                  <router-link to="/about" v-scroll-to="'#About_head'">
                    <el-menu-item index="4-2">客户&伙伴</el-menu-item>
                  </router-link>
                  <router-link to="/join" v-scroll-to="'#About_head'">
                    <el-menu-item index="4-3">加入我们</el-menu-item>
                  </router-link>
                </el-submenu>

                <!--                <el-menu-item index="5" style="padding-top: 10px;height: 90px;">-->
                <!--                  <router-link to="/news">-->
                <!--                    <div style="height: 100%;width: 50px;">-->
                <!--                      寰宇新闻-->
                <!--                    </div>-->
                <!--                  </router-link>-->
                <!--                </el-menu-item>-->

              </el-menu>
            </el-col>
            <!--            导航结束-->
          </el-row>
        </div>
        <!--logo部分结束-->
      </el-header>
      <!--首页头部结束-->

      <!--首页内容开始-->
      <div style="width: 100vw;background-color: #ffffff;margin-top: 85px">
        <!--路由标签-->
        <router-view/>

      </div>
      <!--首页内容结束-->
      <!--      底部开始-->
      <el-footer style="background-color: #001345;height: 100%" :style=yd_size>
        <div class="footer_div">
          <el-row>
            <el-col :span="4">
              <div class="p_size">
                <h4>公司产品</h4><br>
                <p>
                  <router-link to="/product">产品介绍</router-link>
                </p>

              </div>
            </el-col>
            <el-col :span="4">
              <div class="p_size">
                <h4>解决方案</h4><br>
                <p>
                  <router-link to="/pcb">PCB实践</router-link>
                </p>
                <p>
                  <router-link to="/rare-earth">稀土实践</router-link>
                </p>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="p_size">
                <h4>寰宇&客户</h4><br>
                <p>
                  <router-link to="/about">关于寰宇</router-link>
                </p>
                <p>
                  <router-link to="/about" v-scroll-to="'#About_head'">客户&伙伴</router-link>
                </p>
                <p>
                  <router-link to="/join">加入我们</router-link>
                </p>

              </div>
            </el-col>
            <el-col :span="4">
              <div class="p_size">
                <h4>寰宇公众号</h4><br>
                <div class="hw_qr"></div>

              </div>
            </el-col>
            <el-col :span="4">
              <div class="p_size">
                <h4>商务洽谈</h4><br>
                <div class="ws_ls"></div>

              </div>
            </el-col>
            <el-col :span="4">
              <div class="p_size">
                <h4>联系我们</h4><br>
                <p style="font-size: 12px;">电话: 18612032523</p>
                <p style="font-size: 12px;">地址: 苏州市吴江区行家路99号</p>
                <p style="font-size: 12px;text-align: left">邮箱: market@wise-sharing.com</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
      <div style="background-color: black;" :style=yd_size>
        <div style="color: #9c9c9c;padding: 2px;font-size: 12px;width: 1500px;margin: 0 auto;text-align: center">
          Copyright © 2022-{{ year }} WS | 寰宇智享 版权所有
        </div>
        <div style="color: #9c9c9c;padding: 2px;font-size: 12px;width: 1500px;margin: 0 auto;text-align: center">
          <img src="../public/static/gawb.jpg" height="12" width="12"/>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32050902101580"
             rel="noreferrer" target="_blank"> 苏公网安备32050902101580号 </a>
          <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank"> 备案号：苏ICP备2023031578号 </a>
        </div>
      </div>
      <!--      底部结束-->

    </el-container>
    <el-button v-if="btnFlag"
               style="padding: 0;
                !important;
                position: fixed;right: 2%;bottom: 15%;width: 45px;height: 45px;
                 color: #ffffff;background-color: rgba(24,32,99,0.8);border: 1px solid rgba(255,255,255,0)"
               type="primary" @click="backTop">
      UP
    </el-button>
  </div>
</template>


<script>

import '@/assets/css/home.css'

document.title = '寰宇智享';
export default {
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
    //获取今年年份
    var myDate = new Date();
    var tYear = myDate.getFullYear();
    this.year = tYear


  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  data() {
    return {
      btnFlag: false,
      activeIndex: '',
      year: '',
      yd_size: {}
    };
  },
  // 检测路由变化
  watch: {

    $route() {
      this.setCurrentRoute();
    },


  },
  created() {
    //判断是不是移动端
    if (this.isMobile()) {
      this.yd_size = {width: '1500px',};
    } else {

    }
  },
  methods: {

    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // localStorage.setItem('isiphone',flag)
      // console.log(flag)
      return flag;
    },


    setCurrentRoute() {
      var rou = this.$route.path
      if (rou == '/') {
        this.activeIndex = '1'
      } else if (rou == '/pcb') {
        this.activeIndex = '3-1'
      } else if (rou == '/rare-earth') {
        this.activeIndex = '3-2'
      } else if (rou == '/semiconductor') {
        this.activeIndex = '3-3'
      } else if (rou == '/about') {
        this.activeIndex = '4-1'
      } else if (rou == '/join') {
        this.activeIndex = '5'
      }

    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },

  }
}

</script>
