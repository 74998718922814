import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)


const routes = [
    //首页
    {
        path: '/',
        component: HomeView,
    },

    //关于
    {
        path: '/about',
        component: () => import('@/views/AboutUs.vue')
    },
    //PCB解决方案
    {
        path:'/pcb',
        component: ()=>import('@/views/plan_views/planPage.vue')
    },

    //稀土解决方案
    {
        path:'/rare-earth',
        component: ()=>import('@/views/plan_views/plan_Xt.vue')
    },

    //稀土解决方案
    {
        path:'/product',
        component: ()=>import('@/views/product_views/product.vue')
    },

    //加入我们
    {
        path:'/join',
        component: ()=>import('@/views/JoinUs.vue')
    },


    //访问未知页面
    {
        path: '*',
        component: () => import('@/views/error_views/404.vue'),
    },
    //新闻
    {
        path: '/news',
        component: () => import('@/views/News.vue'),
    },



]




const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


export default router
